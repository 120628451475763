import React from 'react';
import { graphql, Link } from 'gatsby';

const Sporgsmal = ({ data }) => {
	const { allStoryblokEntry } = data;
	return (
		<div className="flex flex-col mx-auto my-64 w-grid">
			{allStoryblokEntry.nodes.map((link, i) => (
				<div key={i}>
					<Link to={link.slug} className="m-24 text-primary hover:text-underline">{link.name}</Link>
				</div>
			))}
		</div>
	);
};

export default Sporgsmal;

export const query = graphql`
    query QuestionsQuery {
        allStoryblokEntry(filter: {field_component: {eq: "question-page"}}) {
            nodes {
                field_component
                slug
                name
            }
        }
    }
`;
